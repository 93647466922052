<template>
<div>
	<!-- 添加弹窗 -->
	<el-dialog :title="form.type ==1?'添加资产位置':'添加'" :visible.sync='dialogAddVisible'  width="40%" :close-on-click-modal="false" >
	    <el-form ref="form" :model="form" prop="" :rules="rules" label-width="100px">
			  <el-form-item :label="form.type==1?'位置编码':'仓库编码'" prop="location_code">
				 <el-input  v-model="form.location_code"></el-input>
				<span style="color: #F56C6C;font-size: 12px;font-weight: bold;">(支持输入数字、大小写字母、“-” )</span>
			 </el-form-item>   
			 <el-form-item :label="form.type==1?'位置名称':'仓库名称'" prop="name">
				 <el-input  v-model="form.name"></el-input>
			 </el-form-item>
			<el-form-item :label="form.type == 1?'上级位置':'上级仓库'" prop="pid">
				<el-select ref="treeSelect" v-model="form.parent_name" placeholder="请选择" clearable style="width: 100%;" @change="handleSelect">
					<el-option value="0" label="无上级">无上级</el-option>
					<el-tree   node-key="id" :data="treeData" :props="treePorps" highlight-current ref="tree" @node-click="handleNodeClick"> </el-tree>
				</el-select>
			</el-form-item>
			<el-form-item label="排序" prop="sort">
				 <el-input  v-model="form.sort" type="number" @input="changeNumber"></el-input>
				 <span style="color: #909399;font-size: 12px;">填入序号，从大到小排序</span>
			</el-form-item>
			 <el-form-item label="备注" prop="remark">
				 <el-input type="textarea" rows="5" v-model="form.remark"></el-input>
			 </el-form-item>
	    </el-form>
	     <div slot="footer" class="dialog-footer">
	       <el-button @click="dialogAddVisible = false">取 消</el-button>
	       <el-button type="primary" @click="confirm()">确 定</el-button>
	   </div>
	</el-dialog>
</div>
</template>

<script>
export default {
	inject:['reload'],
    data() {
        return {
			dialogAddVisible:false,
			form:{
			    id:'',
			    location_code:'',
			   name:'',
			   pid:0,
			   parent_name:'',
			   remark:'',
			   sort:0,
			   type:1
			},
			 treeData:[],			
			rules:{
			     location_code:[
			          { required: true,message:'请输入位置编码',trigger: 'blur'}
			     ],
			     name:[
			          { required: true,message:'请输入位置名称',trigger: 'blur'}
			     ], 
			     pid:[
			         { required: true,message:'请选择上级分类',trigger: 'blur'}
			     ],
			},
			treePorps:{
				label:"name"
			}
        }
    },
    created() {

    },
	watch:{
		'form.pid'(){
			this.$nextTick(() => {
				this.$refs.treeSelect.visible =  false
			})
		}
	},
    mounted() {

    },
    methods:{
		changeNumber(val){
			if(typeof val === 'undefined' || val === null || val === ''){
				this.form.sort = 0
			}
	
		},
		handleAdd(id,treeData,type){
			this.form.type =  type
			this.treeData = treeData
			if(id < 1){
				this.$api.get('AssetLocation/add',null,res=>{
				    if(res.code == 200){
				        this.dialogAddVisible = true
				    }
				})
			}else{
				this.handleEdit(id)
			}
		},
		handleEdit(id){	//编辑数据
		    this.$api.get('AssetLocation/edit',{'id':id},res=>{
		        if (res.code == 200) {
		            this.form =  res.data.info
		            this.dialogAddVisible =  true
					this.$nextTick(function(){
						this.$refs.tree.setCurrentKey(res.data.info.pid)
					})
		        }else{
		            this.$message.error(res.msg)
		        }
		    })
		},
		confirm(){
		     let url = 'AssetLocation/add'
		     if (this.form.id) {
		         url = 'AssetLocation/edit'
		     }  
		     this.$api.post(url,this.form,res=>{
		         if (res.code  == 200) {
		            this.dialogAddVisible =  false
		            this.$message.success(res.msg)
		            this.reload();   
		            this.$refs["form"].resetFields();
		            
		         }else{
		             this.$message.error(res.msg)
		         }
		     }) 
		},
		handleSelect(val){
			console.log('select')
			this.form.pid =  val
		},
		handleNodeClick(data){
			this.form.parent_name = data.name
			this.form.pid =  data.id
			form.render()
		}
    },
	
}
</script>
<style scoped>

</style>